import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import "./SidebarRight.css";
import News1 from "../../assets/Okaymon3.avif";
import Retro from "../../assets/Retro.png";

import Modal from "./Modal";

const newsData = [
  {
    title: "11/22 - New Card!",
    description:
      "Jarrett Allen joins Onyeka Okongwu and Isaiah Hartenstein as the final evolution! Which card is next?",
    fullContent:
      "Jarret Allen's (Golem) card has been completed, marking the final evolution in this line. Another fantastic illustration from Simone Perlina for the Cavs' big man in the middle. The next update will likely include the newsletter sign up for people who aren't actively on social media and getting updates that way, some functionality updates to the site, more updates to some art on the site, and perhaps even the final evolution of our Tricky guards...",
    image: News1,
  },
  {
    title: "11/9 - New Additions",
    description:
      "Updates to dark mode, Okongwu and Hartenstein enter the battle, and six new songs!",
    fullContent:
      "Back with some brand new additions.\n\nDark Mode for the site has received some fixes and additions. Brand new animated background to better suit the dark theme, still working out some kinks with the coloring of everything but this should be more readable and better looking overall on mobile and desktop. As always, desktop is the better experience.\n\nTwo new cards! Onyeka Okongwu (Geodude) and Isaiah Hartenstein (Graveler) have been added and you can now see them on the players page. Who do you think Golem will be?\n\nSix new tracks to the OST will be added today, give 'em a listen!\n\nThanks for continuing to check in on the project, next big update will bring the newsletter functionality.",
    image: News1,
  },
  {
    title: "10/28 - Big update!",
    description: "Rudy Gobert emerges from the catacombs.",
    fullContent:
      "Our first new card since release day, how exciting. Head on over to the Players section to see the towering Stonewall type as he guards the Catacombes de Paint-ris.",
    image: News1,
  },
  {
    title: "10/15 - Retro / Audio",
    description:
      "Added a new retro theme for easier navigation and theme songs for each type on their description pages.",
    fullContent:
      "The main page can get kind of visually busy and will get a lot busier as animations and other artsy stuff is added, so I whipped up a retro GameBoy style theme that you can use on desktop or mobile that is much less busy, less colorful, and has a more obvious navigation area. To switch to it just click the little magenta circle in the theme switch area at the bottom right of the screen.\n\nI've also added the theme songs for each type into their descriptions so they can be listened to on the page/when people are reading the descriptions for types. They are still up on YouTube and will be up on streaming platforms sometime soon.",
    image: Retro,
  },
  /*{
    title: "10/10 - First Updates",
    description:
      "Added which player is which Pokemon and way to donate to help get more art done, more quickly. ...Quicker?",
    fullContent:
      "Had a few comments yesterday about people not knowing which player is which Pokemon, so I've added a little indicator under the player's name on each card section to make clear the inspiration for each player's image and general comparison point.\n\nAdditionally I added/fixed a donation link to the contacts & links that wasn't working yesterday that links to a Ko-fi where you can donate as much or as little as you want to the project. I'm paying for all the illustrations, music, and pixel art on the site out of pocket so if anyone wants to help contribute to getting more art done and done quicker, that would be so, so, so appreciated. 100% of that money will be fed right back into the project.\n\nThere were also a lot of responses about wanting art prints or card prints of the illustrations and I'll be looking into that as an additional way to help support the project as well, once I'm done wrangling all these other site updates.\n\nI can't thank everyone enough for the amount of love this is all receiving and I can't stress it enough, but please show the illustrators and Gaatex and Win that love too.",
    image: News1,
  },
  {
    title: "10/9 - Okaymon Launch!",
    description:
      "The site is finally up and I have a lot of stuff I still want to add - here's some info for what's here and what's to come.",
    fullContent:
      "Alright, wow. After what must be like two years of working on this or maybe like three in my mind it's finally time to put the site up.\n\nIf you've clicked into this then let me reiterate how thankful I am for you checking out the project.\n\nI have no idea if there's that many people who will think this is fun, or interesting, let alone cool, but I hope there is.\n\nSo what is up at launch right now is the intro page with a brief explainer, contact and links stuff, FAQs that I took a guess on, big old explanations of how the player types interact with one another, and then the players/stats page featuring, as of 10/7/2024, 33 players.\n\nAs for what's in store/coming up/stuff I know I need to fix.\n\n1. Big write up that explains the project's process more had some rendering issues last night so I'll get that fixed up tomorrow or the next day and put that up. That's first.\n\n2. General site functionality stuff. Make everything load faster and look nicer.\n\n3. Text descriptions for each player that describe a little bit about how/why they were chosen, statistical profile-wise and the art design.\n\n4. Get the light and dark mode fleshed out completely including an animated background for night and day with hidden stuff to click around and find.\n\n5. Social media posts to introduce each player, which should be able to come out in the next few days max.\n\nI really do gotta say thank you for checking this out on more time, and the biggest thank you in the world to the illustrators and Gatex for their continued work on that part of the project. To me that is the coolest part of all of this and their talent is what really stands out here so please give all of them a look if you enjoy their work and let them know.\n\n-Evan",
    image: News1,
  },*/
];

function SidebarRight({ isMobile }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [isSidebarOpen, setSidebarOpen] = useState(false); // For mobile toggle thing

  const handleReadMoreClick = (newsItem) => {
    setModalContent(newsItem);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      {isMobile ? (
        <>
          <div
            className={`sidebar-right-toggle ${isSidebarOpen ? "open" : ""}`}
            onClick={toggleSidebar}
          >
            <div className="sidebar-tab">CLICK ME !</div>
          </div>
          <div
            className={`sidebar-right mobile ${
              isSidebarOpen ? "sidebar-right-visible" : "sidebar-right-hidden"
            }`}
          >
            <div className="news-container">
              <h3 className="sidebar-right-title">LATEST NEWS</h3>
              <div className="news-bar"></div>
            </div>
            {newsData.map((newsItem, index) => (
              <div className="news-card" key={index}>
                <img src={newsItem.image} alt="Icon" />
                <div className="news-card-content">
                  <h1>{newsItem.title}</h1>
                  <p>{newsItem.description}</p>
                  <button
                    className="news-btn"
                    onClick={() => handleReadMoreClick(newsItem)}
                  >
                    Read More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="sidebar-right">
          <div className="news-container">
            <h3 className="sidebar-right-title">LATEST NEWS</h3>
            <div className="news-bar"></div>
          </div>
          {newsData.map((newsItem, index) => (
            <div className="news-card" key={index}>
              <img src={newsItem.image} alt="Icon" />
              <div className="news-card-content">
                <h1>{newsItem.title}</h1>
                <p>{newsItem.description}</p>
                <button
                  className="news-btn"
                  onClick={() => handleReadMoreClick(newsItem)}
                >
                  Read More
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalContent.title}
      >
        {modalContent.fullContent && (
          <ReactMarkdown>{modalContent.fullContent}</ReactMarkdown>
        )}
      </Modal>
    </>
  );
}

export default SidebarRight;
