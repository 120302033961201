// src/data/groupsData.js
const groupsData = {
  1: {
    bgColor: "#226600",
    players: [
      {
        id: 1,
        name: "Wendell Carter Jr.",
        comp: "( Bulbasaur )",
        stats: {
          HP: 55,
          "INS OFF": 59,
          "INS DEF": 79,
          "PER OFF": 67,
          "PER DEF": 56,
          SPEED: 59,
        },
        type1: 12,
        type2: 4,
        imageUrl: "/images/1-WCJ.avif",
        secondaryImageUrl: "/images/WCJ2.avif",
        color1: "#FFFFFF", // First color for the name gradient
        color2: "#FFFFFF", // Second color for the name gradient
      },
      {
        id: 2,
        name: "Kristaps Porzingis",
        comp: "( Ivysaur )",
        stats: {
          HP: 72,
          "INS OFF": 89,
          "INS DEF": 103,
          "PER OFF": 98,
          "PER DEF": 60,
          SPEED: 53,
        },
        type1: 12,
        type2: 4,
        imageUrl: "/images/2-KP.avif",
        secondaryImageUrl: "/images/KP2.avif",
        color1: "#FFFFFF", // First color for the name gradient
        color2: "#FFFFFF", // Second color for the name gradient
      },
      {
        id: 3,
        name: "Joel Embiid",
        comp: "( Venusaur )",
        stats: {
          HP: 85,
          "INS OFF": 123,
          "INS DEF": 125,
          "PER OFF": 132,
          "PER DEF": 70,
          SPEED: 65,
        },
        type1: 12,
        type2: 4,
        imageUrl: "/images/3-Embiid.avif",
        secondaryImageUrl: "/images/KP2.avif",
        color1: "#7AC74C", // First color for the name gradient
        color2: "#FFFFFF", // Second color for the name gradient
      },
    ],
    illImage: "/images/ese.avif",
    footerLink: "https://www.instagram.com/riley_kayote/",
    footerText: "Illustrator - Ese Tuggen",
  },
  2: {
    bgColor:
      "linear-gradient(90deg, rgba(195,65,0,1) 1%, rgba(238,118,48,1) 100%)",
    groupText: "Text related to group 2",
    illImage: "/images/ese.avif",
    footerLink: "https://www.instagram.com/riley_kayote/",
    footerText: (
      <a href="https://www.instagram.com/riley_kayote/">
        Illustrator - Ese Tuggen
      </a>
    ),
    players: [
      {
        id: 4,
        name: "Jonathan Kuminga",
        comp: "( Charmander )",
        stats: {
          HP: 59,
          "INS OFF": 75,
          "INS DEF": 56,
          "PER OFF": 45,
          "PER DEF": 69,
          SPEED: 71,
        },
        group: 2,
        type1: 10,
        type2: null,
        imageUrl: "/images/4-Kuminga.avif",
        secondaryImageUrl: "/images/KP2.avif",
        color1: "#FFFFFF", // First color for the name gradient
        color2: "#EE8130", // Second color for the name gradient
      },

      {
        id: 5,
        name: "Pascal Siakam",
        comp: "( Charmeleon )",
        stats: {
          HP: 80,
          "INS OFF": 97,
          "INS DEF": 70,
          "PER OFF": 60,
          "PER DEF": 86,
          SPEED: 82,
        },
        group: 2,
        type1: 10,
        type2: null,
        imageUrl: "/images/5-Siakam.avif",
        secondaryImageUrl: "/images/KP2.avif",
        color1: "#FFFFFF", // First color for the name gradient
        color2: "#EE8130", // Second color for the name gradient
      },

      {
        id: 6,
        name: "Giannis Antentekoumpo",
        comp: "( Charizard )",
        stats: {
          HP: 95,
          "INS OFF": 130,
          "INS DEF": 120,
          "PER OFF": 51,
          "PER DEF": 105,
          SPEED: 99,
        },
        group: 2,
        type1: 10,
        type2: 3,
        imageUrl: "/images/6-Giannis.avif",
        secondaryImageUrl: "/images/KP2.avif",
        color1: "#FFFFFF", // First color for the name gradient
        color2: "#FFFFFF", // Second color for the name gradient
      },
    ],
  },
  3: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/ese.avif",
    footerLink: "https://www.instagram.com/riley_kayote/",
    footerText: "Illustrator - Ese Tuggen",
    players: [
      {
        id: 7,
        name: "Alperen Sengun",
        comp: "( Squirtle )",
        stats: {
          HP: 67,
          "INS OFF": 75,
          "INS DEF": 56,
          "PER OFF": 79,
          "PER DEF": 43,
          SPEED: 55,
        },
        group: 3,
        type1: 11,
        type2: null,
        imageUrl: "/images/7-Sengun.avif",
        secondaryImageUrl: "/images/KP2.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },

      {
        id: 8,
        name: "Domantas Sabonis",
        comp: "( Wartortle )",
        stats: {
          HP: 100,
          "INS OFF": 89,
          "INS DEF": 69,
          "PER OFF": 98,
          "PER DEF": 56,
          SPEED: 60,
        },
        group: 3,
        type1: 11,
        type2: null,
        imageUrl: "/images/8-Sabonis.avif",
        secondaryImageUrl: "/images/KP2.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },

      {
        id: 9,
        name: "Nikola Jokic",
        comp: "( Blastoise )",
        stats: {
          HP: 125,
          "INS OFF": 137,
          "INS DEF": 80,
          "PER OFF": 137,
          "PER DEF": 59,
          SPEED: 62,
        },
        group: 3,
        type1: 11,
        type2: null,
        imageUrl: "/images/9-Jokic.avif",
        secondaryImageUrl: "/images/KP2.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },

  17: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/simone.avif",
    footerLink: "https://www.instagram.com/simo__world/",
    footerText: "Illustrator - Simone Perlina",
    players: [
      {
        id: 41,
        name: "Nickeil Alexander-Walker",
        comp: "( Zubat )",
        stats: {
          HP: 35,
          "INS OFF": 30,
          "INS DEF": 30,
          "PER OFF": 63,
          "PER DEF": 91,
          SPEED: 84,
        },
        group: 17,
        type1: 4,
        type2: 3,
        imageUrl: "/images/41-NAW.avif",
        secondaryImageUrl: "/images/KP2.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },

      {
        id: 42,
        name: "Kentavious Caldwell-Pope",
        comp: "( Golbat )",
        stats: {
          HP: 70,
          "INS OFF": 40,
          "INS DEF": 40,
          "PER OFF": 80,
          "PER DEF": 115,
          SPEED: 105,
        },
        group: 17,
        type1: 4,
        type2: 3,
        imageUrl: "/images/42-KCP.avif",
        secondaryImageUrl: "/images/KP2.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },

  25: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/simone.avif",
    footerLink: "https://www.instagram.com/simo__world/",
    footerText: "Illustrator - Simone Perlina",
    players: [
      {
        id: 58,
        name: "Jalen Green",
        comp: "( Growlithe )",
        stats: {
          HP: 35,
          "INS OFF": 68,
          "INS DEF": 38,
          "PER OFF": 70,
          "PER DEF": 50,
          SPEED: 89,
        },
        group: 25,
        type1: 10,
        type2: null,
        imageUrl: "/images/58-JalenGreen.avif",
        secondaryImageUrl: "/images/KP2.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },

      {
        id: 59,
        name: "Anthony Edwards",
        comp: "( Arcanine )",
        stats: {
          HP: 76,
          "INS OFF": 102,
          "INS DEF": 55,
          "PER OFF": 92,
          "PER DEF": 95,
          SPEED: 100,
        },
        group: 25,
        type1: 10,
        type2: null,
        imageUrl: "/images/59-Ant.avif",
        secondaryImageUrl: "/images/KP2.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },

  30: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/joey.avif",
    footerLink: "https://www.instagram.com/jebaldn/",
    footerText: "Illustrator - Joey Green",
    players: [
      {
        id: 72,
        name: "Trey Murphy III",
        comp: "( Tentacool )",
        stats: {
          HP: 40,
          "INS OFF": 35,
          "INS DEF": 35,
          "PER OFF": 82,
          "PER DEF": 80,
          SPEED: 78,
        },
        group: 30,
        type1: 11,
        type2: 4,
        imageUrl: "/images/73-TM3.avif",
        secondaryImageUrl: "/images/TM3.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },

      {
        id: 73,
        name: "Mikal Bridges",
        comp: "( Tentacruel )",
        stats: {
          HP: 75,
          "INS OFF": 55,
          "INS DEF": 60,
          "PER OFF": 105,
          "PER DEF": 115,
          SPEED: 90,
        },
        group: 30,
        type1: 11,
        type2: 4,
        imageUrl: "/images/74-Mikal.avif",
        secondaryImageUrl: "/images/Mikal.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },

  31: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/simone.avif",
    footerLink: "https://www.instagram.com/simo__world/",
    footerText: "Illustrator - Simone Perlina",
    players: [
      {
        id: 74,
        name: "Onyeka Okongwu",
        comp: "( Geodude )",

        stats: {
          HP: 63,
          "INS OFF": 70,
          "INS DEF": 90,
          "PER OFF": 30,
          "PER DEF": 45,
          SPEED: 35,
        },
        group: 31,
        type1: 5,
        type2: 6,
        imageUrl: "/images/74-Okongwu.avif",
        secondaryImageUrl: "/images/KP2.png",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
      {
        id: 75,
        name: "Isaiah Hartenstein",
        comp: "( Graveler )",

        stats: {
          HP: 77,
          "INS OFF": 82,
          "INS DEF": 109,
          "PER OFF": 40,
          "PER DEF": 56,
          SPEED: 43,
        },
        group: 31,
        type1: 5,
        type2: 6,
        imageUrl: "/images/75-iHart.avif",
        secondaryImageUrl: "/images/KP2.png",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
      {
        id: 76,
        name: "Jarrett Allen",
        comp: "( Golem )",

        stats: {
          HP: 98,
          "INS OFF": 95,
          "INS DEF": 131,
          "PER OFF": 48,
          "PER DEF": 59,
          SPEED: 52,
        },
        group: 31,
        type1: 5,
        type2: 6,
        imageUrl: "/images/96-Allen.avif",
        secondaryImageUrl: "/images/KP2.png",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },
  40: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/JordanKinley.avif",
    footerLink: "https://www.instagram.com/jrdnkinley_/",
    footerText: "Illustrator - Jordan Kinley",
    players: [
      {
        id: 92,
        name: "Patrick Beverley",
        comp: "( Gastly )",
        stats: {
          HP: 28,
          "INS OFF": 25,
          "INS DEF": 49,
          "PER OFF": 65,
          "PER DEF": 98,
          SPEED: 80,
        },
        group: 40,
        type1: 8,
        type2: 4,
        imageUrl: "/images/92-PatBev.avif",
        secondaryImageUrl: "/images/KP2.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
      {
        id: 93,
        name: "Kyle Lowry",
        comp: "( Haunter )",
        stats: {
          HP: 43,
          "INS OFF": 44,
          "INS DEF": 61,
          "PER OFF": 109,
          "PER DEF": 113,
          SPEED: 94,
        },
        group: 40,
        type1: 8,
        type2: 4,
        imageUrl: "/images/93-Lowry.avif",
        secondaryImageUrl: "/images/KP2.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
      {
        id: 94,
        name: "???",
        comp: "( Gengar )",
        stats: {
          HP: "",
          "INS OFF": "",
          "INS DEF": "",
          "PER OFF": "",
          "PER DEF": "",
          SPEED: "",
        },
        group: 40,
        type1: 8,
        type2: 4,
        imageUrl: "/images/CardBack.avif",
        secondaryImageUrl: "/images/KP2.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },
  41: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/simone.avif",
    footerLink: "https://www.instagram.com/simo__world/",
    footerText: "Illustrator - Simone Perlina",
    players: [
      {
        id: 95,
        name: "Rudy Gobert",
        stats: {
          HP: 80,
          "INS OFF": 66,
          "INS DEF": 165,
          "PER OFF": 10,
          "PER DEF": 51,
          SPEED: 60,
        },
        group: 41,
        type1: 5,
        type2: 6,
        imageUrl: "/images/95-Gobert.avif",
        secondaryImageUrl: "/images/KP2.png",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },
  44: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/ekong.avif",
    footerLink: "https://www.instagram.com/gnokee",
    footerText: "Illustrator - Ekong Caruncho",
    players: [
      {
        id: 100,
        name: "Jaden Ivey",
        comp: "( Voltorb )",
        stats: {
          HP: 30,
          "INS OFF": 55,
          "INS DEF": 25,
          "PER OFF": 55,
          "PER DEF": 40,
          SPEED: 115,
        },
        group: 44,
        type1: 13,
        type2: null,
        imageUrl: "/images/100-Ivey.avif",
        secondaryImageUrl: "/images/CardBack.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },

      {
        id: 101,
        name: "Tyrese Maxey",
        comp: "( Electrode )",
        stats: {
          HP: 60,
          "INS OFF": 110,
          "INS DEF": 35,
          "PER OFF": 110,
          "PER DEF": 65,
          SPEED: 140,
        },
        group: 44,
        type1: 13,
        type2: null,
        imageUrl: "/images/101-Maxey.avif",
        secondaryImageUrl: "/images/CardBack.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },

  47: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/sunset.avif",
    footerLink: "https://www.instagram.com/designsunset/",
    footerText: "Illustrator - Design Sunset",
    players: [
      {
        id: 106,
        name: "Draymond Green",
        comp: "( Hitmonlee )",
        stats: {
          HP: 80,
          "INS OFF": 50,
          "INS DEF": 132,
          "PER OFF": 83,
          "PER DEF": 85,
          SPEED: 70,
        },
        group: 47,
        type1: 2,
        type2: null,
        imageUrl: "/images/106-Draymond.avif",
        secondaryImageUrl: "/images/CardBack.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
      {
        id: 107,
        name: "Jaden McDaniels",
        comp: "( Hitmonchan )",
        stats: {
          HP: 58,
          "INS OFF": 82,
          "INS DEF": 70,
          "PER OFF": 70,
          "PER DEF": 120,
          SPEED: 80,
        },
        group: 47,
        type1: 2,
        type2: null,
        imageUrl: "/images/107-McDaniels.avif",
        secondaryImageUrl: "/images/CardBack.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },

  51: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/ekong.avif",
    footerLink: "https://www.instagram.com/gnokee",
    footerText: "Illustrator - Ekong Caruncho",
    players: [
      {
        id: 113,
        name: "Kevin Love",
        comp: "( Chansey )",
        stats: {
          HP: 140,
          "INS OFF": 80,
          "INS DEF": 80,
          "PER OFF": 100,
          "PER DEF": 30,
          SPEED: 48,
        },
        group: 51,
        type1: 1,
        type2: null,
        imageUrl: "/images/113-Love.avif",
        secondaryImageUrl: "/images/CardBack.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },

  53: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/ekong.avif",
    footerLink: "https://www.instagram.com/gnokee",
    footerText: "Illustrator - Ekong Caruncho",
    players: [
      {
        id: 115,
        name: "Jayson Tatum",
        comp: "( Kangaskhan )",
        stats: {
          HP: 105,
          "INS OFF": 95,
          "INS DEF": 80,
          "PER OFF": 110,
          "PER DEF": 90,
          SPEED: 90,
        },
        group: 53,
        type1: 1,
        type2: null,
        imageUrl: "/images/115-Tatum.avif",
        secondaryImageUrl: "/images/CardBack.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },

  56: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/joey.avif",
    footerLink: "https://www.instagram.com/jebaldn/",
    footerText: "Illustrator - Joey Green",
    players: [
      {
        id: 120,
        name: "Malik Monk",
        comp: "( Staryu )",
        stats: {
          HP: 43,
          "INS OFF": 79,
          "INS DEF": 30,
          "PER OFF": 77,
          "PER DEF": 65,
          SPEED: 84,
        },
        group: 56,
        type1: 11,
        type2: null,
        imageUrl: "/images/120-Monk.avif",
        secondaryImageUrl: "/images/CardBack.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
      {
        id: 121,
        name: "Jamal Murray",
        comp: "( Starmie )",
        stats: {
          HP: 65,
          "INS OFF": 83,
          "INS DEF": 61,
          "PER OFF": 123,
          "PER DEF": 80,
          SPEED: 88,
        },
        group: 56,
        type1: 11,
        type2: 14,
        imageUrl: "/images/121-Jamal.avif",
        secondaryImageUrl: "/images/CardBack.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },
  58: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/sunset.avif",
    footerLink: "https://www.instagram.com/designsunset/",
    footerText: "Illustrator - Design Sunset",
    players: [
      {
        id: 123,
        name: "Shai Gilgeous-Alexander",
        comp: "( Scyther )",
        stats: {
          HP: 74,
          "INS OFF": 120,
          "INS DEF": 68,
          "PER OFF": 103,
          "PER DEF": 98,
          SPEED: 112,
        },
        group: 58,
        type1: 7,
        type2: 3,
        imageUrl: "/images/123-SGA.avif",
        secondaryImageUrl: "/images/CardBack.png",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },
  59: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/joey.avif",
    footerLink: "https://www.instagram.com/jebaldn/",
    footerText: "Illustrator - Joey Green",
    players: [
      {
        id: 124,
        name: "Trae Young",
        comp: "( Jynx )",
        stats: {
          HP: 57,
          "INS OFF": 90,
          "INS DEF": 25,
          "PER OFF": 134,
          "PER DEF": 49,
          SPEED: 122,
        },
        group: 59,
        type1: 15,
        type2: 14,
        imageUrl: "/images/124-Trae.avif",
        secondaryImageUrl: "/images/CardBack.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },
  60: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/simone.avif",
    footerLink: "https://www.instagram.com/simo__world/",
    footerText: "Illustrator - Simone Perlina",
    players: [
      {
        id: 125,
        name: "Donovan Mitchell",
        comp: "( Electabuzz )",
        stats: {
          HP: 60,
          "INS OFF": 127,
          "INS DEF": 52,
          "PER OFF": 120,
          "PER DEF": 81,
          SPEED: 110,
        },
        group: 60,
        type1: 13,
        type2: null,
        imageUrl: "/images/125-Mitchell.avif",
        secondaryImageUrl: "/images/CardBack.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },
  61: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/simone.avif",
    footerLink: "https://www.instagram.com/simo__world/",
    footerText: "Illustrator - Simone Perlina",
    players: [
      {
        id: 126,
        name: "Devin Booker",
        comp: "( Magmar )",
        stats: {
          HP: 69,
          "INS OFF": 99,
          "INS DEF": 59,
          "PER OFF": 137,
          "PER DEF": 88,
          SPEED: 98,
        },
        group: 61,
        type1: 10,
        type2: null,
        imageUrl: "/images/126-Booker.avif",
        secondaryImageUrl: "/images/CardBack.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },

  64: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/simone.avif",
    footerLink: "https://www.instagram.com/simo__world/",
    footerText: "Illustrator - Simone Perlina",
    players: [
      {
        id: 129,
        name: "Bol Bol",
        comp: "( Magikarp )",
        stats: {
          HP: 20,
          "INS OFF": 60,
          "INS DEF": 79,
          "PER OFF": 60,
          "PER DEF": 40,
          SPEED: 60,
        },
        group: 64,
        type1: 11,
        type2: null,
        imageUrl: "/images/129-Bol.avif",
        secondaryImageUrl: "/images/CardBack.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
      {
        id: 130,
        name: "Kevin Durant",
        comp: "( Gyrados )",
        stats: {
          HP: 95,
          "INS OFF": 113,
          "INS DEF": 87,
          "PER OFF": 144,
          "PER DEF": 80,
          SPEED: 81,
        },
        group: 64,
        type1: 11,
        type2: 3,
        imageUrl: "/images/130-Durant.avif",
        secondaryImageUrl: "/images/CardBack.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },

  72: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/simone.avif",
    footerLink: "https://www.instagram.com/simo__world/",
    footerText: "Illustrator - Simone Perlina",
    players: [
      {
        id: 142,
        name: "Zion Williamson",
        comp: "( Aerodactyl )",
        stats: {
          HP: 60,
          "INS OFF": 147,
          "INS DEF": 60,
          "PER OFF": 63,
          "PER DEF": 60,
          SPEED: 110,
        },
        group: 72,
        type1: 6,
        type2: 3,
        imageUrl: "/images/142-Zion.avif",
        secondaryImageUrl: "/images/CardBack.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },
  73: {
    bgColor:
      "linear-gradient(to top, rgba(77,134,255,1) 0%, rgba(99,144,240,1) 51%, rgba(0,0,0,1) 100%)",
    illImage: "/images/ekong.avif",
    footerLink: "https://www.instagram.com/gnokee",
    footerText: "Illustrator - Ekong Caruncho",
    players: [
      {
        id: 143,
        name: "Boban Marjanovic",
        comp: "( Snorlax )",
        stats: {
          HP: 130,
          "INS OFF": 90,
          "INS DEF": 100,
          "PER OFF": 40,
          "PER DEF": 22,
          SPEED: 18,
        },
        group: 73,
        type1: 1,
        type2: null,
        imageUrl: "/images/143-Boban.avif",
        secondaryImageUrl: "/images/CardBack.avif",
        color1: "yellow", // First color for the name gradient
        color2: "#b6acff", // Second color for the name gradient
      },
    ],
  },
};

export default groupsData;
